import { $window } from '../utils/globals';
import className from '../utils/constant-names';

const _element   = '.js-section-tabs';
const $container = $(_element);

if ( $container.length ) {
	const _settings = {
		axis:'x'
	};

	$container.on( 'click', '.js__head nav a', function( event ) {
		event.preventDefault();

		const $this    = $(this);
		const $parent  = $this.parent();
		const $parents = $this.parents( _element );
		const _index   = $parent.index();

		$parent.addClass( className.Active ).siblings().removeClass( className.Active );
		$parents.find( '.js__tabs > *' ).eq( _index ).addClass( className.Active ).siblings().removeClass( className.Active );
	});

	$window.on( 'load', function() {
		$container.find( '.js__scroll-x' ).each( function() {
			const $this = $(this);

			$this.mCustomScrollbar( _settings );
		});
	});
}
