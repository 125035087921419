import { $body } from '../utils/globals';
import className from '../utils/constant-names';

const _element   = '.js-block-rotate-text';
const $container = $(_element);

if ( $container.length ) {

	$container.find( '.js__text' ).each( function() {
		const $this = $(this);
		const $box  = $this.find( '> *' ).html();

		$this.append( '<p>' + $box + '</p>' + '<p>' + $box + '</p>' );
	});
}

