import { $window, $body } from '../utils/globals';
import className from '../utils/constant-names';

let _lastScroll = 0;

$window.on( 'scroll', function() {
	const _scroll = $window.scrollTop();

	let _isLastScroll = ( $body.hasClass( className.IsTouch) ) ? _lastScroll < _scroll && _scroll >= 30 : _lastScroll < _scroll;

	$body.toggleClass( className.ShowFixedHeader, _scroll > 10 );
	$body.toggleClass( className.HideHeader, _isLastScroll );

	_lastScroll = _scroll;
});
