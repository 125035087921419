import { $body } from '../utils/globals';
import className from '../utils/constant-names';

const _element   = '.js-header';
const $container = $(_element);

if ( $container.length ) {

	$container.find( '.js-nav > ul > li' ).hover( function() {
		const $this = $(this);
		const _index = $this.index() + 1;

		$container.attr( 'data-color-menu', _index );
	}, function() {
		$container.attr( 'data-color-menu', 0 );
	});
}

