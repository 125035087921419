import { $window } from '../utils/globals';
import className from '../utils/constant-names';

const _element   = '.js-list-videos';
const $container = $(_element);

if ( $container.length ) {
	let _timer = null;

	$container.find( '.article-video' ).hover( function() {
		const $this    = $(this);
		const $parent  = $this.parent();
		const $parents = $this.parents( _element );

		$parent.addClass( className.Hover );
		$parents.addClass( className.IsHover );
	}, function() {
		clearTimeout( _timer );

		const $this    = $(this);
		const $parent  = $this.parent();
		const $parents = $this.parents( _element );

		_timer = setTimeout(function() {
			$parent.removeClass( className.Hover );
			$parents.removeClass( className.IsHover );
		}, 410);
	});
}
