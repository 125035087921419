import { $document } from '../utils/globals';

const _element = '.gform_wrapper';
const $element = $( _element );

if ( $element.length ) {
	const _files = [
		'gforms_reset_css-css',
		'gforms_formsmain_css-css',
		'gforms_ready_class_css-css',
		'gforms_browsers_css-css',
		'gforms_stripe_frontend-css',
	];

	$.each(_files, function(index, value) {

		const $id = $('#' + value);

		if ($id.length) {
			$id.remove();
		}
	});

	wrapButtons();

	$document.on( 'gform_post_render', function() {
		wrapButtons();
	});

	function wrapButtons() {
		$element.find( '.button' ).each( function() {
			const $this   = $(this);
			const $parent = $this.parent();

			if ( !$parent.hasClass( 'btn' ) ) {
				$this.wrap( '<span class="btn"></span>' );
			}
		});
	}
}
