import Isotope from 'isotope-layout';
import imagesLoaded   from 'imagesloaded';
import className from '../utils/constant-names';

/**
 * Init List Posts
 *
 * @return {void}
 */
const initListPosts = (_element = '.js-list-posts') => {
	const $container = $(_element);

	if (! $container.length ) {
		return false;
	}

	$container.attr( 'data-loading', 'true' );

	const grid = document.querySelector( '.js__content--posts' );

	imagesLoaded( grid, function() {
		$container.attr( 'data-loading', 'false' );

		let $isotope = new Isotope( grid, {
			itemSelector: '.js__col',
			percentPosition: true,
			masonry: {
				columnWidth: '.js__sizer'
			}
		});
	});
};

export default initListPosts;
