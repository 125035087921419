import scrollToSection from '../utils/scroll-to-section';
import { $window, $body } from '../utils/globals';
import className from '../utils/constant-names';

let _timer = null;

$window.on( 'load hashchange', function() {
    const _href = window.location.hash;

    clearTimeout( _timer );

    _timer = setTimeout(function() {
        getValueToScroll( _href );
    }, 600);
});

$( 'a' ).on( 'click', function(event) {
    const $this = $(this);
    const _href = $this.attr( 'href' );

    const _result = getValueToScroll( _href );

    if ( _result == true ) {
        event.preventDefault();

        $body.removeClass( className.ShowNavMain );
    } 
});

function getValueToScroll( _href ) {
    if ( _href.indexOf('#') != -1 ) {
        const _hash = _href.split('#');
        const $id = $('[data-id="' + _hash[1] + '"]');

        if ( $id.length ) {
            scrollToSection( $id );

            return true;
        }
    }

    return false;
}
