/*
 * Polyfills
 */
import './polyfills/object-fit';
import './polyfills/add-body-class-on-page-load';
import './polyfills/add-image-class-if-shapes';
import './polyfills/add-fixed-header-class-on-scroll';
import './polyfills/add-body-class-if-is-touch';
import './polyfills/add-body-class-if-is-ios';
import './polyfills/add-body-class-if-is-ie';
import './polyfills/nav-is-hover-on-touch';
import './polyfills/btn-menu-on-click';
import './polyfills/init-gravity-reset';
import './polyfills/init-scroll-to';
import './polyfills/init-header';
import './polyfills/init-block-rotate-text';
import './polyfills/init-form-main';
import './polyfills/init-vh';
import './polyfills/init-post';
import './polyfills/init-list-playlist';
import './polyfills/init-nav-sections';
import './polyfills/init-section-tabs';
import './polyfills/add-paralax-class';
import './polyfills/add-image-class-if-single-post';
import './polyfills/init-block-wave';
import './polyfills/init-block-brain';
import './polyfills/init-list-videos';
import './polyfills/init-list-stories-main';
import './polyfills/init-section-scroll-x';
import './polyfills/init-aos-animations';

/*
 * Modules
 */

import initAccordions from './modules/init-accordions';
import initParallax from './modules/init-parallax';
import initAjax from './modules/init-ajax';
import initAjaxPopup from './modules/init-ajax-popup';
import initListPosts from './modules/init-list-posts';

initAccordions();
initParallax();
initAjax();
initAjaxPopup();
initListPosts();
