import { $window } from '../utils/globals';

import 'jquery-mousewheel';
import 'malihu-custom-scrollbar-plugin';

const _element   = '.js-list-playlist';
const $container = $(_element);

if ( $container.length ) {

	const _settings = {
		axis:'x'
	};

	$window.on( 'load', function() {
		$container.find( '.js__scroll-x' ).each( function() {
			const $this = $(this);

			$this.mCustomScrollbar( _settings );
		});
	});

	$window.on( 'resize orientationchange', function() {
		$container.find( '.js__scroll-x' ).each( function() {
			const $this = $(this);

			$this.mCustomScrollbar( 'destroy' );
			$this.mCustomScrollbar( _settings );
		});
	});
}

