import { $window } from '../utils/globals';
import className from '../utils/constant-names';

const _element   = '.js-block-wave';
const $container = $(_element);

if ( $container.length ) {

	setHeight();
	wrapTexts();

	$window.on( 'load resize orientationchange', function() {
		setHeight();
		wrapTexts();
	});

	function wrapTexts() {
		$container.find( '.js__holder' ).each( function() {
			const $this = $(this);
			const _text = $this.data( 'text' );
			const _textLength = _text.length;

			let _wrapText = '';

			for (let i = 0; i < _textLength; i++) {
				_wrapText += ( _text[i] == ' ' ) ? '<span>&nbsp;</span>' : '<span>' + _text[i] + '</span>';
			}

			$this.html(_wrapText);
		});
	}

	function setHeight() {
		$container.each( function() {
			const $this = $(this);
			const _offset = parseInt( $this.data( 'offset' ), 10) * 2;

			$this.attr( 'data-height', parseInt($this.innerHeight() - _offset, 10) );
		});
	}
}

