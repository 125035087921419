import { $window } from '../utils/globals';
import className from '../utils/constant-names';

const _element   = '.js-nav-sections';
const $container = $(_element);

if ( $container.length ) {
	let _offsetTop    = [];
	let _offsetBottom = [];
	let _scroll       = 0;
	let _active       = 0;
	let _lastActive   = 0;

	setOffsets();

	$window.on( 'scroll', function() {
		_scroll = $window.scrollTop();

		setActive();
	});

	$window.on( 'load resize orientationchange', function() {
		hasScrollX();
		setOffsets();
	});

	function hasScrollX() {
		const _width = parseInt($window.width(), 10);
		let _navWidth = 0;

		$container.find( '> ul > li' ).each( function() {
			_navWidth += parseInt($(this).innerWidth(), 10);
		});

		$container.toggleClass( className.HasScrollX, _navWidth >= _width );

		if ( $container.hasClass( className.HasScrollX ) ) {
			$container.find( '> ul' ).css( 'min-width', _navWidth + _width );
		} else {
			$container.find( '> ul' ).removeAttr( 'style' );
		}
	}

	function setActive() {
		$.each(_offsetTop, function( _index, _value ) {
			const _scrollTop    = _value;
			const _scrollBottom = _offsetBottom[ _index ];

			if ( _value != false && _scroll >= _scrollTop && _scroll <= _scrollBottom ) {
				_active = _index;
			}
		});

		if ( _active != _lastActive ) {
			const $active = $container.find( 'li' ).eq( _active );

			$active.addClass( className.Active ).siblings().removeClass( className.Active );

			if ( $container.hasClass( className.HasScrollX ) ) {
				const _left = parseInt($active.position().left, 10);

				$container.delay(100).stop(true, true).animate({
					scrollLeft: _left
				}, 1000);
			}
		}

		_lastActive = _active;
	}

	function setOffsets() {
		$container.find( 'a' ).each( function( i ) {
			const $this = $(this);
			const _href = $this.attr( 'href' );

			if ( _href.indexOf( '#' ) >= 0 ) {
				const _arrHref = _href.split( '#' );
				const $id      = $( '[data-id="' + _arrHref[1] + '"]' );

				if ( $id.length ) {
					const _offset    = parseInt($id.offset().top, 10);
					const _header    = parseInt( $( '.header' ).innerHeight(), 10 );
					const _nav       = parseInt( $( _element ).innerHeight(), 10 );
					const _marginTop = parseInt( $id.css( 'margin-top' ), 10 );
					const _height    = parseInt( $id.innerHeight(), 10 );

					let _sum = _offset - _header - _nav - _marginTop;

					_sum = ( _sum >= 0 ) ? _sum : 0;

					_offsetTop[ i ] = _sum;
					_offsetBottom[ i ] = (_offset - _header - _nav) + _height;
				} else {
					_offsetTop[ i ] = false;
					_offsetBottom[ i ] = false;
				}
			} else {
				_offsetTop[ i ] = false;
				_offsetBottom[ i ] = false;
			}
		});
	}
}

