import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { $window, $body } from '../utils/globals';

const _element   = '.js-section-scroll-x';
const $container = $(_element);

if ( $container.length ) {
	$window.on( 'load', function() {
		gsap.registerPlugin(ScrollTrigger);

		const _moreWidth   = ( $window.width() <= 767 ) ? 0 : 50;
		const _windowWidth = parseInt($window.width(), 10);
		const _slides      = parseInt( $container.data( 'slides' ), 10);
		const _maxWidth    = parseInt( ( _slides * _windowWidth ) + _moreWidth, 10);

        // Container
        let container = gsap.timeline({
            scrollTrigger: {
                trigger: _element,
                pin: true,
                start: "top +0px",
                end: '+=' + _maxWidth,
                scrub: 1,
                //markers: true
            }
        });

        container
	        .fromTo( _element + ' .js__slides', { x: 0 }, { x: -_maxWidth, duration: 3 } )
	});
}
