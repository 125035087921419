import initListPosts from '../modules/init-list-posts';
import className from '../utils/constant-names';

/**
 * Init Ajax
 * 
 * @return {void}
 */
const initAjax = (_element = '.js-ajax') => {
  const $container = $(_element);

  if (! $container.length ) {
  	return false;
  }

  const _classNameContent = '.js__content';
  const _classNameActions = '.js__action__holder';
  const _classNameAction  = '.js__action';

  let $page     = {};
  let $pageMore = {};

  $container.on('click', _classNameAction, function(event) {
    event.preventDefault();

    const $this     = $(this);
    const _id       = $this.parents(_element).attr('id');
    const _parentId = '#' + _id;
    const $id       = $(_parentId);
    const href      = $this.attr('href');
    const $parent   = $this.parents( _element );
    const settings  = {
    	url: href
    };

    if (! $id.length ) {
      return false;
    }

    if ( $page[_id + settings.url] != undefined ) {
      setContent(_id + settings.url, $id, $parent);

      return false;
    }
    
    $id.attr('data-loading', true);

    $.ajax(settings)
      .done(function( response ) {
          
        const $response = $(response);
        const $content  = $response.find(_parentId + ' ' + _classNameContent);
        const $more     = $response.find(_parentId + ' ' + _classNameAction);

        $page[_id + settings.url]     = $content.html();
        $pageMore[_id + settings.url] = $more;

        setContent(_id + settings.url, $id, $parent);

        setTimeout(function() {
          $id.attr('data-loading', false);
        }, 400);
      })
      .fail(function( error ) {
          console.log('Error:' + error);
      });
  });

	function setContent(_href, $id, $parent) {
		if ( $id.hasClass('js-ajax--append') ) {
			$id
				.find(_classNameContent)
				.append($page[_href]);
		}

		if ( $id.hasClass('js-ajax--prepend') ) {
			$id
				.find(_classNameContent)
				.prepend($page[_href]);
		}

		if ( $id.hasClass('js-ajax--replace') ) {
			$id
				.find(_classNameContent)
				.html($page[_href]);
		}

		if ($parent.hasClass( 'js-list-posts' ) ) {
			initListPosts();
		}
	}
};

export default initAjax;
