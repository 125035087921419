import { $body } from '../utils/globals';
import className from '../utils/constant-names';

const _element   = '.js-post';
const $container = $(_element);

if ( $container.length ) {
	const $links = $container.find( 'a:not(.btn)' );

	addDataAttributeOnTheLinks();
	setWrapOnTheLinks();

	function addDataAttributeOnTheLinks() {
		$links.each( function() {
			const $this = $(this);
			const _text = $this.text();

			$this.attr( 'data-text', _text );
		});
	}

	function setWrapOnTheLinks() {
		$links.each( function() {
			const $this    = $(this);
			const _arrText = $this.data( 'text' ).split( ' ' );

			$this.addClass( 'fragment-link' );

			let _wordWrap = '';

			$.each( _arrText, function(_index, _word ) {
				_wordWrap += ( _word.length > 0 ) ? '<span><span>' + _word + '&nbsp;</span></span>' : '<br />';
			});

			$this.html( _wordWrap );
		});
	}
}

