import { $window } from '../utils/globals';
import className from '../utils/constant-names';

const _element   = '.js-list-stories-main';
const $container = $(_element);

if ( $container.length ) {
	let _move  = 40;

	$container.find( '.article-story-main' ).hover( function() {
		const $this    = $(this);
		const $parent  = $this.parent();
		const $parents = $this.parents( _element );
		const _index   = $parent.index();

		$parent.addClass( className.Hover );
		$parents.addClass( className.IsHover );

		setMoveOnHover( _index + 1, $parents, $parent );
	}, function() {

		const $this    = $(this);
		const $parent  = $this.parent();
		const $parents = $this.parents( _element );

		$parents.find( '> ul' ).css( 'transform', 'translateX(0px)' );

		$parent.removeClass( className.Hover );
		$parents.removeClass( className.IsHover );
	});

	function setMoveOnHover( _index, $element, $this ) {
		const _winWidth = parseInt( $window.width(), 10 );
		const _eleWidth = parseInt( $element.width(), 10 );

		if ( _winWidth <= _eleWidth && _index % 2 != 0 ) {
			let _eleMove = parseInt(( _eleWidth - _winWidth ) / 2, 10) + _move;

			_eleMove = ( _index % 3 == 0 ) ? -_eleMove : _eleMove;

			$element.find( '> ul' ).css( 'transform', 'translateX(' + _eleMove + 'px)' );
		}
	}
}
