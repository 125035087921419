export default {
	Hover:           'hover',
	Active:          'active',
	Open:            'open',
	IsTouch:         'is-touch',
	IsIos:           'is-ios',
	IsIE:            'is-ie',
	IsEdge:          'is-edge',
	IsHover:         'is-hover',
	HasScrollX:      'has-scroll-x',
	ShowFixedHeader: 'show-fixed-header',
	ShowNavMain:     'show-nav-main',
	ShowPopup:       'show-popup',
	HideHeader:      'hide-header',
	PageLoaded:      'page-loaded',
};
