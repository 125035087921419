import { $htmlBody, $window } from '../utils/globals';

/**
 * Scroll to section
 * 
 * @return {true or false}
 */
const scrollToSection = ( _element = '' ) => {
	let $element = $(_element);

	if (! $element.length) {
		return false;
	}
	
	let _offset      = $element.offset().top;
	let _scroll      = $window.scrollTop();
	let _header      = ( _offset <= _scroll ) ? $('.header').innerHeight() : 0;
	let _wpAdmin     = ( $('#wpadminbar').length ) ? $('#wpadminbar').innerHeight() : 0;
	let _navSections = ( $('.js-nav-sections').length ) ? $('.js-nav-sections').innerHeight() : 0;
	let _marginTop   = parseInt($element.css('margin-top'), 10);
	let _paddingTop  = parseInt($element.css('padding-top'), 10);
	let _moreOffset  = ( _marginTop > 0 && _paddingTop == 0 ) ? _marginTop : 0;

	$htmlBody.delay(100).stop(true, true).animate({
		scrollTop: parseInt(_offset - _header - _wpAdmin - _moreOffset - _navSections, 10)
	}, 1000);
};

export default scrollToSection;
