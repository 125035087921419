import { $body } from '../utils/globals';
import className from '../utils/constant-names';

/**
 * Init Ajax Popup
 *
 * @return {void}
 */
const initAjaxPopup = (_element = '.js-ajax-popup') => {
	const $container = $(_element);

	if (! $container.length ) {
		return false;
	}

	const $popup = $( '.js-section-popup-ajax' )
	const $popupContent = $popup.find( '.js__content' );

	let $page = {};

	$body.on( 'click', function( event ) {
		const $target = $(event.target);
		const _notInViewport = !$target.parents( '.js__popup' ).length && !$target.hasClass( 'js__popup' ) && $body.hasClass( className.ShowPopup );

		if ( _notInViewport ) {
			$body.removeClass( className.ShowPopup );

			const $video  = $target.find( 'video' );
			const $audio  = $target.find( 'audio' );
			const $iframe = $target.find( 'iframe' );

			if ( $video.length ) {
				$video.get(0).pause();
			}

			if ( $audio.length ) {
				$audio.get(0).pause();
			}

			if ( $iframe.length ) {
				const _src = $iframe.attr( 'src' );

				$iframe.attr('src',_src);
			}
		}
	});

	$popup.on( 'click', '.js__close', function( event ) {
		event.preventDefault();


		$body.removeClass( className.ShowPopup );

		const $this  = $(this);
		const $video  = $this.parents( _element ).find( 'video' );
		const $audio  = $this.parents( _element ).find( 'audio' );
		const $iframe = $this.parents( _element ).find( 'iframe' );

		if ( $video.length ) {
			$video.get(0).pause();
		}

		if ( $audio.length ) {
			$audio.get(0).pause();
		}

		if ( $iframe.length ) {
			const _src = $iframe.attr( 'src' );

			$iframe.attr('src',_src);
		}
	});

	$container.on( 'click', function( event ) {
		event.preventDefault();

		const $this = $(this);
		const _hred = $this.attr( 'href' );
		const settings  = {
			url: _hred
		};

		if ( $page[settings.url] != undefined ) {
			$popupContent.html( $page[settings.url] );

			$body.addClass( className.ShowPopup );

			return false;
		}

		$body.attr('data-loading', true);

		$.ajax(settings)
			.done(function( response ) {

				const $response = $(response);
				const $content  = $response.find( '#section-popup' );

				$page[settings.url] = $content.html();

				$popupContent.html( $page[settings.url] );

				setTimeout(function() {
					$body.attr('data-loading', false);

					$body.addClass( className.ShowPopup );
				}, 400);
			})
			.fail(function( error ) {
				console.log('Error:' + error);
			});
	});
};

export default initAjaxPopup;
