import AOS from 'aos';
import { $window } from '../utils/globals';

const _element   = '[data-aos]';
const $container = $(_element);

if ( $container.length ) {
	const _settings = {
		duration: 1000,
		once: true,
		easing: false,
		offset: -120
	};

	AOS.init( _settings );

	$window.on( 'load', function() {
		setTimeout(function() {
			AOS.refresh();
		}, 1000);
	});
}
