import { $body } from '../utils/globals';
import className from '../utils/constant-names';

const _element = '.js-block-brain';
const $element = $( _element );

if ( $element.length ) {
	$element.find( '.js__hover > *' ).hover( function() {
		if ( !$body.hasClass( className.IsTouch ) ) {
			const $this     = $(this);
			const _index    = $this.index();
			const $tooltips = $this.parents( '.js__group' ).find( '.js__tooltips > *' );

			$tooltips.eq( _index ).addClass( className.Active ).siblings().removeClass( className.Active );
		}
	}, function() {
		if ( !$body.hasClass( className.IsTouch ) ) {
			const $this = $(this);
			const $tooltips = $this.parents( '.js__group' ).find( '.js__tooltips > *' );

			$tooltips.removeClass( className.Active );
		}
	});

	$element.find( '.js__hover > *' ).on( 'click', function( event ) {
		event.preventDefault();

		if ( $body.hasClass( className.IsTouch ) ) {
			const $this     = $(this);
			const _index    = $this.index();
			const $tooltips = $this.parents( '.js__group' ).find( '.js__tooltips > *' );

			$tooltips.eq( _index ).toggleClass( className.Active ).siblings().removeClass( className.Active );
			$this.toggleClass( className.Active ).siblings().removeClass( className.Active );
		}
	});

	$element.find( '.js__action' ).on( 'click', function( event ) {
		event.preventDefault();

		const $this   = $(this);
		const $parent = $this.parents( _element );
		const _inside = $parent.attr( 'data-inside' );
		const _toggle = ( _inside == 'false' ) ? 'true' : 'false';

		$parent.attr( 'data-inside', _toggle );
	});
}
